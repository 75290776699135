import React from "react";
import styles from "./Loading.module.css";

export default function Loading() {
    return (
        <div className={"d-flex justify-content-center " + styles.loading}>
            <div className={"spinner-border " + styles.spinnerBorder} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}
