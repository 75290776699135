import {useQuery} from "@apollo/client";
import {GET_PASSIVE_QUERY} from "../queries";
import {ItemPassive} from "../../model/item/ItemPassive";
import {BigBros} from "./useGetBigBros";
import {Nuts} from "./useGetNuts";
import {Tricams} from "./useGetTricams";
import {Hexes} from "./useGetHexes";
import {ApolloError} from "@apollo/client/errors";

export function useGetPassive(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemPassive[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_PASSIVE_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        let bigBros: ItemPassive[] = data.items.bigBros.result
        let hexes: ItemPassive[] = data.items.hexes.result
        let nuts: ItemPassive[] = data.items.nuts.result
        let tricams: ItemPassive[] = data.items.tricams.result
        let items = bigBros.concat(hexes, nuts, tricams)
        return {loading: undefined, error: error, items: items}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    bigBros: BigBros;
    hexes: Hexes;
    nuts: Nuts;
    tricams: Tricams;
}