import React, {useCallback} from "react";
import {GlobalFilter} from "../Filter/GlobalFilter";

export const Actions = React.memo((
    {
        isFiltering,        // Whether filtering is currently enabled on this table
        setIsFiltering,     // Set whether filtering is currently enabled on this table
        globalFilter,       // Global filter state from react table
        setGlobalFilter,    // Set global filter state from react table
        filters,            // Current filters from react table
        canSelect,          // Whether rows can be selected in this table
        compareSelected     // Action that should occur when "Compare Selected" is clicked
    }) => {

    /**
     * Toggles whether the table is in filtering mode
     */
    const toggleIsFiltering = useCallback(() => {
        setIsFiltering(isFiltering => !isFiltering)
    }, [setIsFiltering])

    return (
        <div className={"d-sm-flex flex-row-reverse"}>
            <div className="d-flex d-sm-inline">
                {canSelect ? (
                    <button type="button" className={"btn btn-primary flex-grow-1"}
                            onClick={() => compareSelected()}>
                        Compare Selected
                    </button>
                ):null}
                <button type="button" className={"d-inline d-sm-none btn btn-light " + (canSelect ? "ms-2" : "flex-grow-1")}
                        data-bs-toggle="modal" data-bs-target="#mobileFilterModal">
                    {filters.length === 0 ? "Filter" : "Filtered"}
                </button>
            </div>
            <div className={"flex-grow-1 ms-sm-2 me-sm-2 mt-2 mt-sm-0"}>
                <GlobalFilter className="form-control" placeholder="Search.." globalFilter={globalFilter}
                              setGlobalFilter={setGlobalFilter}/>
            </div>
            <button type="button" className={"btn " + (isFiltering ? "btn-primary" : "btn-light") + " d-none d-sm-inline "}
                    onClick={toggleIsFiltering}>
                Filter
            </button>
        </div>
    )
})