export class UnitsUtils {
    static inchesFractionDigits = 2
    static poundsForceFractionDigits = 0
    static ouncesFractionDigits = 2

    static millimetresToInches(millimetres: number): number {
        return millimetres / 25.4
    }

    static millimetresToInchesString(millimetres: number): string {
        return this.millimetresToInches(millimetres).toFixed(UnitsUtils.inchesFractionDigits)
    }

    static kilonewtonsToPoundForce(kilonewtons: number): number {
        return kilonewtons * 224.80894309971
    }

    static kilonewtonsToPoundForceString(kilonewtons: number): string {
        return this.kilonewtonsToPoundForce(kilonewtons).toFixed(UnitsUtils.poundsForceFractionDigits)
    }

    static gramsToOunces(grams: number): number {
        return grams * 0.035273962
    }

    static gramsToOuncesString(grams: number): string {
        return this.gramsToOunces(grams).toFixed(UnitsUtils.ouncesFractionDigits)
    }

    static gramsToKilosAndGrams(totalGrams: number): { kilos: number, grams: number } {
        return {
            kilos: Math.floor(totalGrams / 1000),
            grams: totalGrams % 1000
        }
    }

    static ouncesToPoundsAndOunces(totalOunces: number): { pounds: number, ounces: number } {
        return {
            pounds: Math.floor(totalOunces / 16),
            ounces: totalOunces % 16
        }
    }
}