import React, {FunctionComponent} from "react";
import {Jumbotron} from "../../Common/Jumbotrom/Jumbotron";
import {Email} from "./Email/Email";

export const Contact: FunctionComponent = () => {
    return (
        <div>
            <Jumbotron>
                <h1 className="display-4">
                    Contact
                </h1>
                <p className="col-12 offset-0 col-md-8 offset-md-2 lead">
                    If you would like to get in contact then please do!
                </p>
            </Jumbotron>
            <div className={"container"}>
                <Email address="hello@camparator.com"/>
            </div>
        </div>
    )
}