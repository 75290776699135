import {BaseTableService} from "../../../service/TableService/BaseTableService";
import {Item} from "../../../model/item/Item";
import React, {FunctionComponent, useMemo} from "react";
import {UidUtils} from "../../../utils/UidUtils";
import {useHistory} from "react-router-dom"
import {SettingsContext, SettingsContextType} from "../../../contexts/SettingsContext";
import {Ad} from "../../Common/Ad/Ad";
import {ItemsTable} from "../../Common/ItemsTable/ItemsTable";
import Loading from "../../Common/Loading/Loading";
import {Error} from "../../Common/Error/Error"

type CompareInternalProps = {
    itemTypeId: string
    tableService: BaseTableService<Item>
    initiallySelected: number[]|undefined
}

export const CompareInternal: FunctionComponent<CompareInternalProps> = ({itemTypeId, tableService, initiallySelected}) => {
    const history = useHistory()
    const { loading, error, items } = tableService.useGetItems()

    const compareSelected = (uids: number[]) => {
        let encodedUids = UidUtils.uidsToBase64(uids)
        history.push("/compare/" + itemTypeId + "/selection/" + encodedUids)
    }

    const { units } = React.useContext(SettingsContext) as SettingsContextType;

    const columns = useMemo(() => {
        return tableService.getColumns(units);
    }, [tableService, units])

    return (
        <div className={"container"}>
            <Ad slot={"3469205681"}/>
            <h1>{tableService.getTitle()}</h1>
            {loading ? (
                <Loading/>
            ) : null}
            {error ? (
                <div>
                    <Error message={error.message}/>
                </div>
            ) : null}
            {items ? (
                <ItemsTable columns={columns} items={items} compareSelected={compareSelected}
                            initiallySelected={initiallySelected}/>
            ) : null}
            <Ad slot={"4747462486"}/>
        </div>
    )
}
