import {BaseTableService} from "./BaseTableService";
import {ItemTricam} from "../../model/item/ItemTricam";
import {useGetTricams} from "../../networking/hooks/useGetTricams";
import {Units} from "../../model/settings/Units";
import {Item} from "../../model/item/Item";

export class TricamTableService extends BaseTableService<ItemTricam> {
    constructor() {
        super(useGetTricams);
    }

    getName(): string {
        return "Tricam"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            this.weight(units),
            this.strengthCustom({
                headerNoUnits: "Strength Cam",
                accessor: (item: Item) => item.strength,
                priority: 3,
                units: units
            }),
            this.strengthCustom({
                headerNoUnits: "Strength Chock",
                accessor: (item: ItemTricam) => item.strengthChock,
                priority: 3,
                units: units
            }),
            this.strengthCustom({
                headerNoUnits: "Strength 3Chock",
                accessor: (item: ItemTricam) => item.strength3Chock,
                priority: 3,
                units: units
            }),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units),
        ];
    }
}
