export class Range {
    min: number
    max: number
    unitPercentSize: number

    constructor(min: number, max: number, unitPercentSize: number) {
        this.min = min;
        this.max = max;
        this.unitPercentSize = unitPercentSize;
    }
}
