import React, {FunctionComponent} from "react";
import {Blocked} from "./Blocked/Blocked";
import {Placeholder} from "./Placeholder/Placeholder";
import {AdMode} from "../../../../model/AdMode";
import {Real} from "./Real/Real";

type AdInternalProps = {
    className?: string
    mode: AdMode
    client: string
    slot: string
}

export const AdInternal: FunctionComponent<AdInternalProps> = ({className, mode, client, slot}) => {
    switch (mode) {
        case AdMode.BLOCKED:
            return (
                <Blocked className={className}/>
            )
        case AdMode.PLACEHOLDER:
            return (
                <Placeholder className={className} slot={slot}/>
            )
        case AdMode.REAL:
            return (
                <Real className={className} client={client} slot={slot}/>
            )
    }
}