import React, {FunctionComponent} from "react";
import styles from "../TipJar.module.css"
import {Link} from "react-router-dom";

export const Thanks: FunctionComponent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isRecurring = Object.fromEntries(urlParams).hasOwnProperty("recurring")

    return (
        <div className={styles.tipJar}>
            <h1>Thanks!</h1>
            <p>
                Thanks so much for your contribution to Camparator. It is very much appreciated.
            </p>
            {isRecurring ? (
                <p>
                    If/when you wish to cancel your ongoing subscription - please reach out to me (details on
                    the <Link to={"/contact/"}>Contact Page</Link>) and I can unsubscribe you.
                </p>
            ): null}
        </div>
    )
}