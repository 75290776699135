import {FunctionComponent} from "react";
import {Item} from "../../../../model/item/Item";
import {StatisticsUtils} from "../../../../utils/StatisticsUtils";
import {Units} from "../../../../model/settings/Units";

type CompareSelectionProps = {
    items: Item[]
    units: Units
}

export const Statistics: FunctionComponent<CompareSelectionProps> = ({items, units}) => {
    const weight = StatisticsUtils.itemsWeightString(items, units.weight)

    return (
        <table className="table table-bordered" style={{width: "auto"}}>
            <tbody>
                <tr>
                    <th>Total Weight</th>
                    <td>{weight}</td>
                </tr>
            </tbody>
        </table>
    )
}