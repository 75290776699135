import {FunctionComponent} from "react";
import styles from "./Placeholder.module.css"
import {Utils} from "../../../../../utils/Utils";

type PlaceholderProps = {
    className?: string
    slot: string
}

export const Placeholder: FunctionComponent<PlaceholderProps> = ({className, slot}) => {
    if (process.env.NODE_ENV !== 'development') {
        return null
    }
    return (
        <div className={Utils.classNames([styles.placeholder, className])}>
            Placeholder Advertisement <span className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline">(#{slot})</span>
        </div>
    )
}