import React, {FunctionComponent, useEffect, useState} from "react";
import ConfigService from "../../../service/ConfigService";
import {AdMode} from "../../../model/AdMode";
import {AdInternal} from "./AdInternal/AdInternal";

type AdProps = {
    className?: string
    slot: string
}

export const Ad: FunctionComponent<AdProps> = ({className, slot}) => {
    const client = ConfigService.getAdClient()
    const [mode, setMode] = useState(ConfigService.isPlaceholderAd() ? AdMode.PLACEHOLDER : AdMode.REAL)

    useEffect(() => {
        if(mode === AdMode.PLACEHOLDER) return
        const element = document.querySelector(".adsbygoogle") as HTMLElement;
        if(!element) return
        try {
            const isAdblockEnabled = getComputedStyle(element).display === "none"
            setMode(isAdblockEnabled ? AdMode.BLOCKED : AdMode.REAL)
        } catch (e) {
            console.error(e)
        }
    }, [mode])

    return (
        <AdInternal className={className} mode={mode} client={client} slot={slot}/>
    )
}