import {Item} from "../../model/item/Item";
import {ApolloError} from "@apollo/client/errors";
import {Units} from "../../model/settings/Units";
import {UnitType} from "../../model/settings/UnitType";
import {ItemCam} from "../../model/item/ItemCam";
import {ItemUtils} from "../../utils/ItemUtils";
import SelectColumnFilter from "../../components/Common/ItemsTable/Filter/SelectColumnFilter";
import ColorCell from "../../components/Common/ItemsTable/ColorCell/ColorCell";
import {UnitsUtils} from "../../utils/UnitsUtils";

type GetItemsHook<SomeItem extends Item> = () => {loading: boolean|undefined, error: ApolloError|undefined, items: SomeItem[]|undefined};

type StrengthCustom = {
    headerNoUnits: string
    accessor: (item: any) => any
    priority: number
    units: Units
}

export abstract class BaseTableService<SomeItem extends Item> {
    useGetItems: GetItemsHook<SomeItem>

    protected constructor(useGetItems: GetItemsHook<SomeItem>) {
        this.useGetItems = useGetItems;
    }

    abstract getName(): string

    abstract getColumns(units: Units): any[]

    getTitle(): string {
        return `${this.getName()} Comparison`
    }

    getNameForDescription(): string {
        return `${this.getName().toLowerCase()}`
    }

    getNameForDescriptionPlural(): string {
        return `${this.getName().toLowerCase()}s`
    }

    // Unit names

    sizeUnitsName(unitType: UnitType): string {
        switch (unitType) {
            case UnitType.IMPERIAL:
                return "in"
            case UnitType.METRIC:
                return "mm"
        }
    }

    strengthUnitsName(unitType: UnitType): string {
        switch (unitType) {
            case UnitType.IMPERIAL:
                return "lbf"
            case UnitType.METRIC:
                return "kN"
        }
    }

    weightUnitsName(unitType: UnitType): string {
        switch (unitType) {
            case UnitType.IMPERIAL:
                return "oz"
            case UnitType.METRIC:
                return "g"
        }
    }

    // Unit conversion

    millimetresValue(millimetres: number|null, unitType: UnitType): string|null {
        if(millimetres == null) return null
        switch (unitType) {
            case UnitType.IMPERIAL:
                return UnitsUtils.millimetresToInchesString(millimetres)
            case UnitType.METRIC:
                return `${millimetres}`
        }
    }

    kilonewtonValue(kilonewtons: number|null, unitType: UnitType): string|null {
        if(kilonewtons == null) return null
        switch (unitType) {
            case UnitType.IMPERIAL:
                return UnitsUtils.kilonewtonsToPoundForceString(kilonewtons)
            case UnitType.METRIC:
                return `${kilonewtons}`
        }
    }

    gramsValue(grams: number|null, unitType: UnitType): string|null {
        if(grams == null) return null
        switch (unitType) {
            case UnitType.IMPERIAL:
                return UnitsUtils.gramsToOuncesString(grams)
            case UnitType.METRIC:
                return `${grams}`
        }
    }

    // Common Columns

    name() {
        return {
            Header: 'Name',
            accessor: (item: Item) => ItemUtils.determineName(item),
            canFilter: false,
            priority: 0,
        }
    }

    generation() {
        return {
            Header: 'Generation',
            accessor: (item: Item) => item.model?.generation?.name,
            Filter: SelectColumnFilter,
            filter: 'equals',
            canFilter: true,
            priority: 1,
        }
    }

    brand() {
        return {
            Header: 'Brand',
            accessor: (item: Item) => item.model?.brand?.name,
            Filter: SelectColumnFilter,
            filter: 'equals',
            canFilter: true,
            priority: 1,
        }
    }

    model() {
        return {
            Header: 'Model',
            accessor: (item: Item) => item.model?.name,
            Filter: SelectColumnFilter,
            filter: 'equals',
            canFilter: true,
            priority: 1,
        }
    }

    size() {
        return {
            Header: 'Size',
            accessor: (item: ItemCam) => item.size,
            canFilter: false,
            priority: 1,
        }
    }

    color() {
        return {
            Header: 'Color',
            accessor: (item: Item) => item.color?.name,
            Filter: SelectColumnFilter,
            filter: 'equals',
            canFilter: true,
            Cell: ColorCell,
            disableTd: true,
            priority: 3,
            infoStyle: (item: ItemCam) => ItemUtils.determineColorStyle(item)
        }
    }

    weight(units: Units) {
        return {
            Header: `Weight (${this.weightUnitsName(units.weight)})`,
            accessor: (item: Item) => this.gramsValue(item.weight, units.weight),
            canFilter: false,
            priority: 2,
        }
    }

    strengthCustom(props: StrengthCustom) {
        return {
            Header: `${props.headerNoUnits} (${this.strengthUnitsName(props.units.strength)})`,
            accessor: (item: Item) => this.kilonewtonValue(props.accessor(item), props.units.strength),
            canFilter: false,
            priority: props.priority,
        }
    }

    strength(units: Units) {
        return this.strengthCustom({
            headerNoUnits: "Strength",
            accessor: (item: Item) => item.strength,
            priority: 3,
            units: units
        })
    }

    rangeLower(units: Units) {
        return {
            Header: `Lower (${this.sizeUnitsName(units.size)})`,
            accessor: (item: Item) => this.millimetresValue(item.rangeLower, units.size),
            canFilter: false,
            priority: 2,
        }
    }

    rangeUpper(units: Units) {
        return {
            Header: `Upper (${this.sizeUnitsName(units.size)})`,
            accessor: (item: Item) => this.millimetresValue(item.rangeUpper, units.size),
            canFilter: false,
            priority: 2,
        }
    }

    range(units: Units) {
        return {
            Header: `Range (${this.sizeUnitsName(units.size)})`,
            accessor: (item: Item) => this.millimetresValue(item.range, units.size),
            canFilter: false,
            priority: 4,
        }
    }
}
