import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './components/App/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'font-awesome/css/font-awesome.css'
import {BrowserRouter} from "react-router-dom";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {persistCache} from 'apollo3-cache-persist';
import ConfigService from "./service/ConfigService";
import {SettingsProvider} from "./contexts/SettingsProvider";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const cache = new InMemoryCache();

persistCache({
    cache: cache,
    storage: window.localStorage
}).then(() => {
    const client = new ApolloClient({
        uri: `${ConfigService.getApiAddress()}/graphql`,
        cache: cache
    });

    const rootElement = document.getElementById("root");
    if (rootElement && rootElement.hasChildNodes()) {
        ReactDOM.hydrate(
            <React.StrictMode>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <SettingsProvider>
                            <App />
                        </SettingsProvider>
                    </BrowserRouter>
                </ApolloProvider>
            </React.StrictMode>,
            rootElement
        );
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <SettingsProvider>
                            <App />
                        </SettingsProvider>
                    </BrowserRouter>
                </ApolloProvider>
            </React.StrictMode>,
            rootElement
        );
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})
