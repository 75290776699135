import {Uint8ArrayUtils} from "./Uint8ArrayUtils";
import {StringUtils} from "./StringUtils";

export class UidUtils {

    static uidsToBase64(uids: number[]): string {
        const uint8Arrays = uids.map(uid => Uint8ArrayUtils.fromInt16(uid))
        const uint8Array = Uint8ArrayUtils.merge(uint8Arrays)
        return Uint8ArrayUtils.toBase64(uint8Array)
    }

    static base64ToUids(base64: string): number[] {
        const uint8Array = Uint8ArrayUtils.fromBase64(base64)
        const uint8Arrays = Uint8ArrayUtils.split(uint8Array, 2)
        return uint8Arrays.map(uint8Array => Uint8ArrayUtils.toInt(uint8Array))
    }

    static legacyExtractUids(search: string): number[] {
        let uids: number[] = []
        let split = StringUtils.trimLeft(search, "?").split("&")
        split.forEach(item => {
            let itemSplit = item.split("=")
            let uid = parseInt(StringUtils.trimLeft(itemSplit[0], "i"))
            if(!isNaN(uid)) {
                uids.push(uid)
            }
        })
        return uids
    }

    static base64ToLegacyQueryString(base64: string): string {
        return this.base64ToUids(base64)
            .map(uid => "i" + uid + "=1")
            .join("&")
    }
}