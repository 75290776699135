import React, {FunctionComponent} from "react";
import {EmailLink} from "../../../Common/EmailLink/EmailLink";

type EmailProps = {
    address: string
}

export const Email: FunctionComponent<EmailProps> = ({address}) => {
    return (
        <div className="text-center">
            <a href={`mailto:${address}`}>
                <i className="fa fa-envelope-o fa-3x"/>
            </a>
            <p>
                <EmailLink address={address}/>
            </p>
        </div>
    )
}