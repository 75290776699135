import React, {FunctionComponent} from "react";
import styles from "./EmailLink.module.css";

type EmailLinkProps = {
    address: string
}

export const EmailLink: FunctionComponent<EmailLinkProps> = ({address}) => {
    const reversed = address.split("").reverse().join("")
    return (
        <a href={`mailto:${address}`} className={styles.reversed}>
            {reversed}
        </a>
    )
}