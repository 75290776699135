import {useEffect, useState} from "react";
import {Units} from "../model/settings/Units";
import {UnitType} from "../model/settings/UnitType";
import {Utils} from "../utils/Utils";

export const useUnits = () => {
    const KEY = "units";
    const DEFAULT = {
        size: UnitType.METRIC,
        strength: UnitType.METRIC,
        weight: UnitType.METRIC
    };

    /**
     * Takes a possibly unsafe object and makes it safe
     * @param value Possibly unsafe object
     * @return Safe object with null or undefined values set as the default
     */
    const validated = (value: Units): Units => {
        return {
            size: Utils.validatedEnum(UnitType, value.size, DEFAULT.size),
            strength: Utils.validatedEnum(UnitType, value.strength, DEFAULT.strength),
            weight: Utils.validatedEnum(UnitType, value.weight, DEFAULT.weight)
        }
    }

    /**
     * Parse JSON and ensure it returns a validated object
     * @param json Raw JSON
     * @return Safe to use object
     */
    const parse = (json: string): Units => {
        try {
            return validated(JSON.parse(json))
        } catch (e) {
            return DEFAULT
        }
    }

    const [currentValue, setCurrentValue] = useState<Units>(() => {
        const json = localStorage.getItem(KEY);
        if(json == null) {
            return DEFAULT
        }
        return parse(json)
    });

    // on every render, re-subscribe to the storage event
    useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === KEY) {
                if(e.newValue) {
                    setCurrentValue(parse(e.newValue));
                } else {
                    setCurrentValue(DEFAULT);
                }
            }
        };
        window.addEventListener('storage', handler);
        return () => {
            window.removeEventListener('storage', handler);
        };
    });

    return [currentValue, setCurrentValue] as const;
}