import React, {FunctionComponent, useEffect} from 'react';
import {Nav} from './Nav/Nav';
import {Switch} from "react-router-dom";
import {Home} from "../Pages/Home/Home";
import {NotFound} from "../Pages/NotFound/NotFound";
import {Compare} from "../Pages/Compare/Compare";
import {CompareSelectionLegacy} from "../Pages/CompareSelectionLegacy/CompareSelectionLegacy";
import {CompareSelection} from "../Pages/CompareSelection/CompareSelection";
import {Contact} from "../Pages/Contact/Contact";
import {MobileApp} from "../Pages/MobileApp/MobileApp";
import PageRoute from "./PageRoute/PageRoute";
import {CompareEverythingForward} from "../Pages/CompareEverythingForward/CompareEverythingForward";
import {Perma} from "../Pages/Perma/Perma";
import {AdsenseCssHack} from "./AdsenseCssHack/AdsenseCssHack";
import {TipJar} from "../Pages/TipJar/TipJar";
import {Thanks} from "../Pages/TipJar/Thanks/Thanks";
import ConfigService from "../../service/ConfigService";
import {Test} from "../Pages/Test/Test";
import {AnalyticsTrack} from "./Analytics/AnalyticsTrack";
import {CheckPageDescription} from "./Debug/CheckPageDescription/CheckPageDescription";
import {Info} from "../Pages/Info/Info";
import {Privacy} from "../Pages/Privacy/Privacy";
import {PrivacyIOS} from "../Pages/PrivacyIOS/PrivacyIOS";
import {Analytics} from "../../analytics/Analytics";

export const App: FunctionComponent = () => {

    useEffect(() => {
        Analytics.instance.setUp().then()
    }, [])

    return (
        <div id={"app"} className={"d-flex flex-column h-100"}>
            <Nav/>
            <Switch>
                <PageRoute exact path="/" component={Home} fullWidth={true}
                           description={"Easily compare different types of climbing gear! Including cams, nuts, hexes, " +
                                        "and more!"}/>
                <PageRoute exact path="/compare/:itemTypeId/" component={Compare}/>
                <PageRoute exact path="/compare/:itemTypeId/selection/" component={CompareSelectionLegacy}/>
                <PageRoute exact path="/compare/:itemTypeId/selection/:encodedUids/" component={CompareSelection}/>
                <PageRoute exact path="/compare/" component={CompareEverythingForward}/>
                <PageRoute exact path="/app/" component={MobileApp} fullWidth={true} title={"Mobile App"}
                           description={"Camparator for iOS brings the climbing gear comparison of the web to your " +
                           "mobile device. Easily compare different types of climbing gear! Including cams, nuts, " +
                           "hexes, and more!"}/>
                <PageRoute exact path="/contact/" component={Contact} fullWidth={true} title={"Contact"}
                           description={"Get in contact with the creator of Camparator! Please let me know of any " +
                           "mistakes or issues you occur when using the site."} />
                <PageRoute exact path="/perma/:slug/" component={Perma}
                           description={"A direct link to commonly compared climbing equipment"} />
                <PageRoute exact path="/privacy/" component={Privacy} description={"Details of various privacy policies"}/>
                <PageRoute exact path="/privacy/ios/" component={PrivacyIOS} description={"Privacy Policy for the Camparator app for iOS (including iPhone and iPad)"}/>
                <PageRoute exact path="/tipjar/" component={TipJar}
                           description={"Despite Camparator being covered in ads the website does not break even (who knew comparing climbing gear was a niche market?). So if you wish to tip something then please do so."} />
                <PageRoute exact path="/tipjar/thanks/" component={Thanks}
                           description={"Thanks so much for your contribution to Camparator. It is very much appreciated."}/>
                <PageRoute exact path="/info/" component={Info} description={"About this version of Camparator"}/>
                {ConfigService.isNodeDevelopment() ? (
                    <PageRoute exact path="/test/" component={Test} description={"test"}/>
                ):null}
                <PageRoute component={NotFound}/>
            </Switch>
            <AnalyticsTrack/>
            <AdsenseCssHack ids={["root", "app"]}/>
            {ConfigService.isNodeDevelopment() ? (
                <CheckPageDescription/>
            ):null}
        </div>
    );
}
