import {useQuery} from "@apollo/client";
import {GET_BALL_NUTS_QUERY} from "../queries";
import {ItemBallNut} from "../../model/item/ItemBallNut";
import {ApolloError} from "@apollo/client/errors";

export function useGetBallNuts(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemBallNut[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_BALL_NUTS_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        return {loading: undefined, error: error, items: data.items.ballNuts.result}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    ballNuts: BallNuts;
}

export interface BallNuts {
    result: ItemBallNut[];
}