export class Uint8ArrayUtils {

    /**
     * Convert a number (int32) into a Uint8Array
     * @param int32 A number (int32)
     * @return A Uint8Array
     * @private
     */
    static fromInt32(int32: number): Uint8Array {
        return new Uint8Array([
            (int32 & 0xff000000) >> 24,
            (int32 & 0x00ff0000) >> 16,
            (int32 & 0x0000ff00) >> 8,
            (int32 & 0x000000ff)
        ]);
    }

    /**
     * Convert a number (int16) into a Uint8Array
     * @param int16 A number (int16)
     * @return A Uint8Array
     * @private
     */
    static fromInt16(int16: number): Uint8Array {
        return new Uint8Array([
            (int16 & 0x0000ff00) >> 8,
            (int16 & 0x000000ff)
        ]);
    }

    /**
     * Convert a Uint8Array into a number
     * @param uint8Array A Uint8Array
     * @return A number
     * @private
     */
    static toInt(uint8Array: Uint8Array): number {
        let buffer = Buffer.from(uint8Array)
        return buffer.readUIntBE(0, uint8Array.length);
    }

    /**
     * Convert a Uint8Array into a base64 encoded strubg
     * @param uint8Array A Uint8Array
     * @return A base64 encoded string
     * @private
     */
    static toBase64(uint8Array: Uint8Array): string {
        let output = [];
        for (let i = 0, length = uint8Array.length; i < length; i++) {
            output.push(String.fromCharCode(uint8Array[i]));
        }
        let result = Uint8ArrayUtils.btoa(output.join(''));
        result = result.replaceAll("_", "-")
        result = result.replaceAll("/", "+")
        return result
    }

    /**
     * Converts a base64 string to a Uint8Array
     * @param base64 A base64 string
     * @return Uint8Array representation of base64 string
     * @private
     */
    static fromBase64 (base64: string): Uint8Array {
        base64 = base64.replaceAll("-", "_")
        base64 = base64.replaceAll("+", "/")
        return Uint8Array.from(Uint8ArrayUtils.atob(base64), c => c.charCodeAt(0));
    }

    /**
     * Combines an array of Uint8Array to a single Uint8Array
     * @param uint8Arrays An array of Uint8Array
     * @return New single Uint8Array
     * @private
     */
    static merge(uint8Arrays: Uint8Array[]): Uint8Array {
        const length = Uint8ArrayUtils.uint8ArraysLength(uint8Arrays)
        let mergedArray = new Uint8Array(length);
        let lengthSoFar = 0
        uint8Arrays.forEach(uint8Array => {
            mergedArray.set(uint8Array, lengthSoFar);
            lengthSoFar += uint8Array.length
        })
        return mergedArray
    }

    static split(uint8Array: Uint8Array, length: number): Uint8Array[] {
        let output: Uint8Array[] = []
        let i = 0
        let array: number[] = []
        uint8Array.forEach(x => {
            array.push(x)
            if(i === length - 1) {
                output.push(new Uint8Array(array))
                i = 0
                array = []
            } else {
                i++
            }
        })
        if(array.length !== 0) {
            output.push(new Uint8Array(array))
        }
        return output
    }

    /**
     * Gets the total length of all Uint8Array in an array
     * @param uint8Arrays An array of Uint8Array
     * @private
     */
    private static uint8ArraysLength(uint8Arrays: Uint8Array[]): number {
        if(uint8Arrays.length === 0) return 0
        return uint8Arrays
            .map(uint8Array => uint8Array.length)
            .reduce((a, b) => a + b)
    }

    /**
     * Shim for legacy btoa
     *
     * The btoa() method creates a Base64-encoded ASCII string from a binary string (i.e., a String object in which
     * each character in the string is treated as a byte of binary data).
     *
     * You can use this method to encode data which may otherwise cause communication problems, transmit it, then use
     * the atob() method to decode the data again. For example, you can encode control characters such as ASCII values
     * 0 through 31.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/btoa
     *
     * @param stringToEncode The binary string to encode.
     * @return An ASCII string containing the Base64 representation of stringToEncode.
     * @private
     */
    private static btoa(stringToEncode: string): string {
        return Buffer.from(stringToEncode).toString("base64");
    }


    /**
     * Shim for legacy atob
     *
     * The atob() function decodes a string of data which has been encoded using Base64 encoding. You can use the
     * btoa() method to encode and transmit data which may otherwise cause communication problems, then transmit it and
     * use the atob() method to decode the data again. For example, you can encode, transmit, and decode control
     * characters such as ASCII values 0 through 31.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/atob
     *
     * @param encodedData A binary string contains an base64 encoded data.
     * @return An ASCII string containing decoded data from encodedData.
     * @private
     */
    private static atob(encodedData: string): string {
        encodedData = encodedData.replace("-", "_")
        encodedData = encodedData.replace("+", "/")
        return Buffer.from(encodedData, "base64").toString();
    }
}