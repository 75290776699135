import React, {FunctionComponent} from "react";
import styles from "./Footer.module.css";
import {Link} from "react-router-dom";

export const Footer: FunctionComponent = () => {
    let year = new Date().getFullYear();

    return (
        <>
            <footer className={"footer mt-auto py-2 bg-light " + styles.footer}>
                <div className="container">
                <span className="text-muted">
                    &copy; Camparator 2016 - {year} | <Link to="/contact/">Contact</Link><br/>
                    By using this website, you agree to the use of cookies.
                </span>
                </div>
            </footer>
            <div className={styles.postFooter}/>
        </>
    )
}