import React, {FunctionComponent} from "react";
import styles from "../AdInternal.module.css";
import {Adsense} from "@ctrl/react-adsense";
import ConfigService from "../../../../../service/ConfigService";
import {Utils} from "../../../../../utils/Utils";

type RealProps = {
    className?: string
    client: string
    slot: string
}

export const Real: FunctionComponent<RealProps> = ({className, client, slot}) => {
    const adTest = ConfigService.isAdTest()
    return (
        <div className={Utils.classNames([styles.adsenseWrapper, className])}>
            <Adsense client={client} slot={slot} data-adtest={adTest ? "on" : "off"}/>
        </div>
    )
}