import {BaseTableService} from "../../../service/TableService/BaseTableService";
import {Item} from "../../../model/item/Item";
import React, {FunctionComponent, useEffect, useMemo} from "react";
import {useHistory} from "react-router";
import {Statistics} from "./Statistics/Statistics";
import {Redirect} from "react-router-dom";
import {SettingsContext, SettingsContextType} from "../../../contexts/SettingsContext";
import {Ad} from "../../Common/Ad/Ad";
import Loading from "../../Common/Loading/Loading";
import {RangeChart} from "../../Common/RangeChart/RangeChart";
import {ItemsTable} from "../../Common/ItemsTable/ItemsTable";
import {ItemUtils} from "../../../utils/ItemUtils";
import {Utils} from "../../../utils/Utils";
import {Analytics} from "../../../analytics/Analytics";

type CompareSelectionInternalProps = {
    itemTypeId: string
    tableService: BaseTableService<Item>
    uids: number[]
    setDescription: (description: string|undefined) => void
}

export const CompareSelectionInternal: FunctionComponent<CompareSelectionInternalProps> = ({itemTypeId, tableService, uids, setDescription}) => {
    const { loading, error, items } = tableService.useGetItems();
    const history = useHistory();

    let selectedItems = useMemo(() => {
        return items?.filter(item => {
            return uids.includes(item.uid)
        }).sort((a, b) => {
            let aRangeMid = ((a.rangeLower ?? 0) + (a.rangeUpper ?? 0)) / 2
            let bRangeMid = ((b.rangeLower ?? 0) + (b.rangeUpper ?? 0)) / 2
            if(aRangeMid < bRangeMid) return -1
            if(aRangeMid > bRangeMid) return 1
            return 0
        })
    }, [items, uids])

    useEffect(() => {
        if(!selectedItems || selectedItems.length === 0) return
        const selectedIds = selectedItems.map((item) => item.id)
        Analytics.instance.compareEvent(itemTypeId, selectedIds)
    }, [itemTypeId, selectedItems])

    useEffect(() => {
        if(!selectedItems) return
        let names = selectedItems.slice(0,3).map(item => `'${ItemUtils.determineName(item)}'`)
        if(selectedItems.length > 3) {
            const remaining = selectedItems.length - 3
            names.push(`${remaining} other ${remaining >= 2 ? "items" : "item"}`)
        }
        setDescription(`Compare ${Utils.listToHumanReadable(names)} on a ${tableService.getNameForDescription()} comparison chart and figure out their differences!`)
    }, [selectedItems, setDescription, tableService])

    const { units } = React.useContext(SettingsContext) as SettingsContextType;

    const columns = useMemo(() => (
        tableService.getColumns(units)
    ), [tableService, units])

    if(selectedItems === null || selectedItems?.length === 0) {
        return (
            <Redirect to={"/compare/" + itemTypeId + "/"}/>
        )
    }

    const compareOtherItems = () => {
        history.push({pathname: "/compare/" + itemTypeId + "/"})
    }

    const addItems = () => {
        history.push({
            pathname: "/compare/" + itemTypeId + "/",
            state: {
                uids: uids
            }
        })
    }

    return (
        <div className={"container"}>
            <Ad slot={"3469205681"}/>
            <div>
                <h1 className={"float-md-start"}>{tableService.getTitle()}</h1>
                <div className={"float-md-end mt-2 d-sm-flex"}>
                    <button type="button" className="btn btn-danger width-xs-100 flex-sm-grow-1" onClick={compareOtherItems}>
                        Compare Other Items
                    </button>
                    <button type="button" className="btn btn-primary float-end ms-sm-2 mt-2 mt-sm-0 width-xs-100 flex-sm-grow-1" onClick={addItems}>
                        Add Items to Compare
                    </button>
                </div>
                <div className="clearfix"/>
            </div>
            {loading ? (
                <Loading/>
            ) : null}
            {error ? (
                <div>
                    {error.toString()}
                </div>
            ) : null}
            {selectedItems ? (
                <div>
                    <h2>Statistics</h2>
                    <Statistics items={selectedItems} units={units} />

                    <h2>Range Chart</h2>
                    <RangeChart items={selectedItems}/>

                    <h2>Comparison Table</h2>
                    <ItemsTable columns={columns} items={selectedItems} compareSelected={null} canSelect={false}/>
                </div>
            ) : null}
            <Ad slot={"4747462486"}/>
        </div>
    )
}