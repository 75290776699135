import React, {FunctionComponent} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {UidUtils} from "../../../utils/UidUtils";

type CompareSelectionLegacyParams = {
    itemTypeId: string
}

export const CompareSelectionLegacy: FunctionComponent = () => {
    let location = useLocation();
    const { itemTypeId } = useParams<CompareSelectionLegacyParams>();

    const uids = UidUtils.legacyExtractUids(location.search)
    const encoded = UidUtils.uidsToBase64(uids)

    if(encoded === "") {
        return (
            <Redirect to={"/compare/" + itemTypeId + "/"}/>
        )
    }

    return (
        <Redirect to={"/compare/" + itemTypeId + "/selection/" + encoded}/>
    )
}