import React, {useRef} from "react";
import {Utils} from "../../../../../utils/Utils";
import styles from "./Row.module.css"

export function Row({row, didChangeSelected, initialIsSelected, showInfoModel, canSelect}) {
    const checkboxRef = useRef()

    const toggleSelected = () => {
        if(!checkboxRef.current) return
        const isSelected = !checkboxRef.current.checked
        checkboxRef.current.checked = isSelected
        didChangeSelected(row.original.uid, isSelected)
    }

    const handleCheckboxClick = (event) => {
        event.stopPropagation()
    }

    const handleCheckboxChange = (event) => {
        didChangeSelected(row.original.uid, event.target.checked)
    }

    const ref = useRef()

    return (
        <tr {...row.getRowProps()} onClick={toggleSelected} className={styles.row} ref={ref}>
            {canSelect ? (
                <td>
                    <input ref={checkboxRef} className={"checkbox " + styles.checkbox} type="checkbox"
                           onChange={handleCheckboxChange} onClick={handleCheckboxClick} checked={initialIsSelected}/>
                </td>
            ):null}
            {row.cells.map((cell, i) => {
                const disableTd = !!cell.column.disableTd
                if(disableTd) {
                    return (
                        cell.render('Cell', {
                            key: i,
                            className: Utils.priorityToClass(cell.column.priority ?? -1)
                        })
                    )
                } else {
                    return (
                        <td className={Utils.priorityToClass(cell.column.priority ?? -1)} key={i}
                            // Return an array of prop objects and react-table will merge them appropriately
                            {...cell.getCellProps([
                                {
                                    className: cell.column.className,
                                    style: cell.column.style,
                                },
                            ])}
                        >
                            {cell.render('Cell')}
                        </td>
                    )
                }
            })}
            <td>
                <button type="button" className="btn btn-link btn-anchor" data-bs-toggle="modal"
                        data-bs-target="#itemInfoModal"
                        onClick={() => showInfoModel()}>
                    <i className="fa fa-info-circle" aria-hidden="true"/>
                </button>
            </td>
        </tr>
    )
}