import {BaseTableService} from "./BaseTableService";
import {ItemActive} from "../../model/item/ItemActive";
import {useGetActive} from "../../networking/hooks/useGetActive";
import {Units} from "../../model/settings/Units";

export class ActiveTableService extends BaseTableService<ItemActive> {
    constructor() {
        super(useGetActive);
    }

    getName(): string {
        return "Active Protection"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            this.weight(units),
            this.strength(units),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units),
        ];
    }
}
