import {Item} from "../model/item/Item";
import {CSSProperties} from "react";

export class ItemUtils {

    static determineName(item: Item) {
        return `${item.model.brand.name} ${item.model.name} ${item.size}`
    }

    static determineColorStyle(item: Item): CSSProperties {
        const hex = item.color?.hex
        const textHex = item.color?.textHex
        return {
            backgroundColor: hex ? "#" + hex : undefined,
            color: textHex ? "#" + textHex : undefined,
        }
    }
}