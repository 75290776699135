export type TipLinks = {
    oneOff: string
    monthly: string
}

export type TipOption = {
    amount: number
    links: TipLinks
}

export type TipJarCurrency = {
    name: string
    symbol: string
    options: TipOption[]
}

export type TipJarInfo = {
    currencies: { [key: string]: TipJarCurrency }
}

export enum TipJarRecurring {
    ONE_OFF = "one-off",
    MONTHLY = "monthly"
}