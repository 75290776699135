import {TipJarInfo} from "../model/TipJar";
import tipJarTest from "../tipjar-test.json"
import tipJarProd from "../tipjar-prod.json"
import {Environment} from "../model/Environment";

export default class ConfigService {
    static environment = ConfigService._getEnvironment()

    static getApiAddress(): String {
        switch (this.environment) {
            case Environment.PRODUCTION:
                return "https://api.camparator.com"
            case Environment.STAGE:
                return "https://api-stage.camparator.com"
            case Environment.DEV:
                return "https://api-dev.camparator.com"
            case Environment.LOCAL:
                if(process.env.REACT_APP_API == null) {
                    return "https://api-dev.camparator.com"
                }
                return process.env.REACT_APP_API;
        }
    }

    static getAnalyticsApiAddress(): String {
        switch (this.environment) {
            case Environment.PRODUCTION:
                return "https://api.analytics.camparator.com"
            case Environment.STAGE:
                return "https://api.analytics-stage.camparator.com"
            case Environment.DEV:
                return "https://api.analytics-dev.camparator.com"
            case Environment.LOCAL:
                if(process.env.REACT_ANALYTICS_APP_API == null) {
                    return "https://api.analytics-dev.camparator.com"
                }
                return process.env.REACT_ANALYTICS_APP_API;
        }
    }

    static getAdClient(): string {
        return "ca-pub-4176517408637619"
    }

    static isAdTest(): boolean {
        return this.environment !== Environment.PRODUCTION
    }

    static isPlaceholderAd(): boolean {
        switch (this.environment) {
            case Environment.PRODUCTION:
            case Environment.DEV:
            case Environment.STAGE:
                return false
            case Environment.LOCAL:
                return window.location.hostname !== "local.camparator.com"
        }
    }

    static getTipJarInfo(): TipJarInfo {
        switch (this.environment) {
            case Environment.PRODUCTION:
                return tipJarProd
            case Environment.DEV:
            case Environment.STAGE:
            case Environment.LOCAL:
                return tipJarTest
        }
    }

    static isNodeDevelopment() {
        return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    }

    static getEnvironmentString(): string {
        switch (this.environment) {
            case Environment.PRODUCTION:
                return "Production"
            case Environment.STAGE:
                return "Stage"
            case Environment.DEV:
                return "Dev"
            case Environment.LOCAL:
                return "Local"
        }
    }

    static _getEnvironment(): Environment {
        switch (window.location.hostname) {
            case "camparator.com":
            case "www.camparator.com":
                return Environment.PRODUCTION
            case "dev.camparator.com":
                return Environment.DEV
            case "stage.camparator.com":
                return Environment.STAGE
            case "127.0.0.1":
            case "localhost":
            case "local.camparator.com":
                return Environment.LOCAL
        }
        if(window.location.hostname.startsWith("192.168")) {
            return Environment.LOCAL
        }
        throw new Error("Could not determine environment for " + window.location.hostname)
    }
}
