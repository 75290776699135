import {useQuery} from "@apollo/client";
import {GET_NUTS_QUERY} from "../queries";
import {ItemNut} from "../../model/item/ItemNut";
import {ApolloError} from "@apollo/client/errors";

export function useGetNuts(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemNut[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_NUTS_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        return {loading: undefined, error: error, items: data.items.nuts.result}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    nuts: Nuts;
}

export interface Nuts {
    result: ItemNut[];
}