import {BaseTableService} from "./BaseTableService";
import {useGetNuts} from "../../networking/hooks/useGetNuts";
import {ItemNut} from "../../model/item/ItemNut";
import {Units} from "../../model/settings/Units";

export class NutTableService extends BaseTableService<ItemNut> {
    constructor() {
        super(useGetNuts);
    }

    getName(): string {
        return "Nut"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            this.weight(units),
            this.strength(units),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units),
        ];
    }
}
