import {FunctionComponent} from "react";
import styles from "./Blocked.module.css"
import {Utils} from "../../../../../utils/Utils";
import {Link} from "react-router-dom";

type BlockedProps = {
    className?: string
}

export const Blocked: FunctionComponent<BlockedProps> = ({className}) => {
    return (
        <div className={Utils.classNames([styles.blocked, className])}>
            <p>
                Please disable your adblocker
            </p>
            <p>
                Alternatively, please consider donating to the <Link to={"/tipjar/"}>Tip Jar</Link> to browse ad free
                and guilt free!
            </p>
        </div>
    )
}