import styles from "./InfoModal.module.css"
import {ItemUtils} from "../../../../utils/ItemUtils";

export function InfoModal({columns, item}) {
    return (
        <div className="modal fade" id="itemInfoModal" tabIndex={-1} aria-labelledby="itemInfoModalTitle"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="itemInfoModalTitle">
                            {item && ItemUtils.determineName(item)}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-bordered"}>
                            <tbody>
                                {item && columns.map((column, i) => {
                                    if(column.priority <= 0) {
                                        return null
                                    }
                                    const style = column.infoStyle ? column.infoStyle(item) : {}

                                    return (
                                        <tr key={i}>
                                            <th className={styles.cell}>
                                                {column.Header}
                                            </th>
                                            <td className={styles.cell} style={style}>
                                                {column.accessor(item)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}