import React, {FunctionComponent} from "react";
import {useParams} from 'react-router-dom'
import {NotFound} from "../NotFound/NotFound";
import {TableServiceFactory} from "../../../service/TableService/TableServiceFactory";
import {CompareInternal} from "./CompareInternal";

type CompareProps = {
    setTitle: (title: string|undefined) => void
    setDescription: (description: string|undefined) => void
    location?: {
        state?: {
            uids?: number[]
        }
    }
}

type CompareParams = {
    itemTypeId: string
}

export const Compare: FunctionComponent<CompareProps> = ({setTitle, setDescription, location}) => {
    const { itemTypeId } = useParams<CompareParams>();

    const tableService = TableServiceFactory.create(itemTypeId)

    if(tableService == null) {
        return (
            <NotFound setTitle={setTitle} setDescription={setDescription}/>
        )
    }

    setTitle(tableService.getTitle())
    setDescription(
        `Compare different climbing ${tableService.getNameForDescriptionPlural()}. Compare their ` +
        `sizes on ${tableService.getNameForDescription()} comparison charts.`
    )

    return (
        <CompareInternal itemTypeId={itemTypeId} tableService={tableService} initiallySelected={location?.state?.uids}/>
    )
}

