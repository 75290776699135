import {useQuery} from "@apollo/client";
import {GET_TRICAMS_QUERY} from "../queries";
import {ItemTricam} from "../../model/item/ItemTricam";
import {ApolloError} from "@apollo/client/errors";

export function useGetTricams(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemTricam[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_TRICAMS_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        return {loading: undefined, error: error, items: data.items.tricams.result}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    tricams: Tricams;
}

export interface Tricams {
    result: ItemTricam[];
}