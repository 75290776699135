import {BaseAnalytics} from "./BaseAnalytics";
import ConfigService from "../service/ConfigService";

export class Analytics extends BaseAnalytics {
    static instance = new Analytics(ConfigService.getAnalyticsApiAddress(), "camparator_web")

    unitsSize: String|null = null
    unitsStrength: String|null = null
    unitsWeight: String|null = null

    constructReport(): any {
        return {
            "browser": {
                "user_agent": navigator.userAgent
            },
            "units": {
                "size": this.unitsSize,
                "strength": this.unitsStrength,
                "weight": this.unitsWeight,
            }
        }
    }

    async pageViewEvent(path: String, search: String) {
        await this.event("page_view", {
            "path": path,
            "search": search
        })
    }

    async compareEvent(itemType: String, ids: String[]) {
        const items = ids.map((id) => {
            return {
                "slug": id
            }
        })
        await this.event("compare", {
            "items": items,
            "item_type": itemType
        })
    }
}