import React, {FunctionComponent} from "react";
import styles from "./RangeChart.module.css"
import {Bar} from "./Bar/Bar";
import {Axis} from "./Axis/Axis";
import {Range} from "../../../model/range-chart/Range";
import {AxisTitle} from "./AxisTitle/AxisTitle";
import {Item} from "../../../model/item/Item";

type RangeChartProps = {
    items: Item[]
}

export const RangeChart: FunctionComponent<RangeChartProps> = ({items}) => {

    const determineRange = (items: Item[]): Range  => {
        let i;
        let rangeMin: number|null = null;
        let rangeMax: number|null = null;
        for (i = 0; i < items.length; i++) {
            let rangeLower = items[i].rangeLower
            let rangeUpper = items[i].rangeUpper

            if (rangeMin == null || (rangeLower && rangeLower < rangeMin)) {
                rangeMin = rangeLower;
            }
            if (rangeMax == null || (rangeUpper && rangeUpper > rangeMax)) {
                rangeMax = rangeUpper;
            }
        }
        return new Range(
            rangeMin ?? 0,
            rangeMax ?? 0,
            100/((rangeMax ?? 0) - (rangeMin ?? 0))
        )
    }

    let range = determineRange(items);

    return (
        <div className={styles.rangeChart}>
            {items.map((item, i) => (
                <Bar key={item.id} item={item} range={range}/>
            ))}
            <Axis range={range}/>
            <AxisTitle/>
        </div>
    )
}
