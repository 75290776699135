import React, {useCallback} from "react";
import styles from "../ItemsTable.module.css";
import {Utils} from "../../../../utils/Utils";
import ColumnValue from "./ColumnValue/ColumnValue";
import ColumnFilter from "./ColumnFilter/ColumnFilter";
import rowStyles from "../Body/Row/Row.module.css"

export const Header = React.memo((
    {
        headerGroups = [],      // The header groups from react table
        rows,                   // The rows from react table
        bodyRef,                // Reference to the <Body> DOM element
        selectedIdsRef,         // Reference used to store the currently selected IDs
        selectAllRef,           // Reference to the select all checkbox <input> DOM element
        canSelect = false,      // Whether rows can be selected in this table
        isFiltering = false     // Whether filtering is currently enabled on this table
    }) => {

    /**
     * Get the UIDs for all of the currently displayed items (i.e. after filters etc)
     */
    const displayedUids = useCallback(() => {
        return rows.map(row => row.original.uid)
    }, [rows])

    /**
     * Handles when the select all checkbox is changed. We alter the DOM directly rather than using a
     * React-y way of doing this to increase performance with large amounts of data in the table.
     * @param event Checkbox change event
     */
    const handleSelectAllChange = useCallback((event) => {
        bodyRef.current.querySelectorAll(`.${rowStyles.checkbox}`).forEach(checkbox => {
            checkbox.checked = event.target.checked
        })
        if(event.target.checked) {
            selectedIdsRef.current = displayedUids()
        } else {
            const idsToRemove = displayedUids()
            selectedIdsRef.current = selectedIdsRef.current.filter(id => !idsToRemove.includes(id))
        }
    }, [bodyRef, displayedUids, selectedIdsRef])

    return (
        <thead>
            {headerGroups.map((headerGroup, i) => {
                return [
                    <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup_${i}_headings`}>
                        {canSelect ? (
                            <th>
                                <input className={"checkbox " + styles.selectAll} type="checkbox" ref={selectAllRef}
                                       onChange={handleSelectAllChange}/>
                            </th>
                        ):null}
                        {headerGroup.headers.map(column => {
                            return (
                                <th key={column.id}
                                    className={Utils.priorityToClass(column.priority ?? -1)}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <ColumnValue column={column}/>
                                </th>
                            )
                        })}
                        <th/>
                    </tr>,
                    isFiltering ? (
                        <tr {...headerGroup.getHeaderGroupProps()}  key={`headerGroup_${i}_filters`} className="d-none d-sm-table-row">
                            {canSelect ? (
                                <th/>
                            ):null}
                            {headerGroup.headers.map(column => (
                                <th key={column.id}
                                    className={Utils.priorityToClass(column.priority ?? -1)}
                                    {...column.getHeaderProps()}>
                                    <ColumnFilter column={column}/>
                                </th>
                            ))}
                            <th/>
                        </tr>
                    ):null
                ]
            })}
        </thead>
    )
})