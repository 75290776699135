import {useQuery} from "@apollo/client";
import {GET_CAMS_QUERY} from "../queries";
import {ItemCam} from "../../model/item/ItemCam";
import {ApolloError} from "@apollo/client/errors";

export function useGetCams(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemCam[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_CAMS_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        return {loading: undefined, error: error, items: data.items.cams.result}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    cams: Cams;
}

export interface Cams {
    result: ItemCam[];
}