import React, {FunctionComponent, useEffect} from "react";
import {Link} from "react-router-dom";

type NotFoundProps = {
    setTitle: (title: string|undefined) => void
    setDescription: (description: string|undefined) => void
}

export const NotFound: FunctionComponent<NotFoundProps> = ({setTitle, setDescription}) => {
    const urlParams = new URLSearchParams(window.location.search);

    if(!urlParams.has("404")) {
        window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?404`;
    }

    useEffect(() => {
        setTitle("Page not found")
        setDescription("The requested resource could not be found but may be available again in the future.")
    }, [setDescription, setTitle])

    const goBack = () => {
        // eslint-disable-next-line no-restricted-globals
        history.back();

        if(urlParams.has("404")) {
            // eslint-disable-next-line no-restricted-globals
            history.back();
        }
    }

    return (
        <div className={"container"}>
            <h1>Error 404 - Page not found!</h1>
            <p>
                The requested resource could not be found but may be available again in the future.
            </p>
            <p>
                You can either:
            </p>
            <ul>
                <li>
                    <button className="btn-link btn-anchor" onClick={goBack}>Go back</button> and try again
                </li>
                <li>
                    Return to the <Link to={"/"}>Home Page</Link>
                </li>
            </ul>
        </div>
    )
}