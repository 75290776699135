import {gql} from "@apollo/client";

export const GET_ACTIVE_QUERY = gql`
    query {
        items {
            cams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                        axle {
                            name
                        }
                        stem {
                            name
                        }
                        stemFlexibility {
                            name
                        }
                        lobes
                        slingLoop {
                            name
                        }
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    passiveStrength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            ballNuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_CAMS_QUERY = gql`
    query {
        items {
            cams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                        axle {
                            name
                        }
                        stem {
                            name
                        }
                        stemFlexibility {
                            name
                        }
                        lobes
                        slingLoop {
                            name
                        }
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    passiveStrength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_BALL_NUTS_QUERY = gql`
    query {
        items {
            ballNuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_PASSIVE_QUERY = gql`
    query {
        items {
            bigBros {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            hexes {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            nuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            tricams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    strengthChock
                    strength3Chock
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_BIG_BROS_QUERY = gql`
    query {
        items {
            bigBros {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_HEXES_QUERY = gql`
    query {
        items {
            hexes {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_NUTS_QUERY = gql`
    query {
        items {
            nuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_TRICAMS_QUERY = gql`
    query {
        items {
            tricams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    strengthChock
                    strength3Chock
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`;

export const GET_ITEMS_QUERY = gql`
    query {
        items {
            cams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                        axle {
                            name
                        }
                        stem {
                            name
                        }
                        stemFlexibility {
                            name
                        }
                        lobes
                        slingLoop {
                            name
                        }
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    passiveStrength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            ballNuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            bigBros {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            hexes {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            nuts {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    rangeLower
                    rangeUpper
                    range
                }
            }
            tricams {
                result {
                    id
                    uid
                    model {
                        id
                        name
                        generation {
                            name
                        }
                        brand {
                            id
                            name
                        }
                        year
                    }
                    size
                    color {
                        id
                        name
                        hex
                        textHex
                    }
                    weight
                    strength
                    strengthChock
                    strength3Chock
                    rangeLower
                    rangeUpper
                    range
                }
            }
        }
    }
`