import {FunctionComponent, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";

export const CheckPageDescription: FunctionComponent = () => {
    const location = useLocation()

    const metaDescription = useMemo(() => {
        return document.querySelector("meta[name=description]") as HTMLMetaElement
    }, [])

    useEffect(() => {
        if(metaDescription.content === "") {
            alert(`Page ${location.pathname} has no description`)
        }
    })
    return null
}