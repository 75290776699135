import React, {FunctionComponent} from "react";
import styles from "./Axis.module.css"
import {Utils} from "../../../../utils/Utils";

type AxisProps = {
    range: {min: number, max: number, unitPercentSize: number}
}

export const Axis: FunctionComponent<AxisProps> = ({range}) => {
    const MAX_AXIS_LABELS = 15;

    let axisInterval = Math.floor((range.max - range.min) / MAX_AXIS_LABELS);
    if(axisInterval===0) axisInterval = 1;

    let axisStart = Math.ceil(range.min+axisInterval/2)
    let axisEnd = Math.floor(range.max-axisInterval/2)

    let oddOrEven = false;

    return (
        <div className={styles.axis}>
            {Utils.arrayRange(axisStart - axisStart%axisInterval, axisEnd - axisEnd%axisInterval, axisInterval).map(i => {
                let left = range.unitPercentSize * (i - range.min);

                let oddOrEvenClass = oddOrEven ? styles.axisLabelOdd : styles.axisLabelEven
                oddOrEven = !oddOrEven

                return (
                    <div key={i} className={styles.axisLabel + " " + oddOrEvenClass} style={{
                        left: `calc(${left}% - 25px)`
                    }}>
                        <div className="hatch-mark"/>
                        <div className="value">{i}</div>
                    </div>
                )
            })}
        </div>
    )
}