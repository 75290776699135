import React, {FunctionComponent} from "react";
import useFitText from "use-fit-text";

export const FitText: FunctionComponent = ({children}) => {
    const { fontSize, ref } = useFitText();

    return (
        <div ref={ref} style={{fontSize}}>
            {children}
        </div>
    )
}