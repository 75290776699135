import React, {useState} from "react";
import {useAsyncDebounce} from "react-table";

export function GlobalFilter({className, placeholder, globalFilter, setGlobalFilter}) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <input
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            className={className}
            placeholder={placeholder}
        />
    )
}