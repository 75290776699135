import React, {FunctionComponent} from "react";
import styles from "./Jumbotron.module.css";

type JumbotronProps = {
    className?: string|null
}

export const Jumbotron: FunctionComponent<JumbotronProps> = ({children, className = null}) => {
    return (
        <div className={styles.jumbotron + (className ? " " + className : "")}>
            <div className={"p-5 mb-4 rounded-3"}>
                <div className="container-fluid py-3">
                    {children}
                </div>
            </div>
        </div>
    )
}