import {BaseTableService} from "./BaseTableService";
import {ItemBallNut} from "../../model/item/ItemBallNut";
import {Units} from "../../model/settings/Units";
import {useGetBallNuts} from "../../networking/hooks/useGetBallNuts";

export class BallNutTableService extends BaseTableService<ItemBallNut> {
    constructor() {
        super(useGetBallNuts);
    }

    getName(): string {
        return "Ball Nut"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            this.weight(units),
            this.strength(units),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units),
        ];
    }
}
