import * as React from "react";
import {SettingsContext} from "./SettingsContext";
import {useUnits} from "../hooks/useUnits";

export const SettingsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [units, setUnits] = useUnits()

    return (
        <SettingsContext.Provider value={{ units, setUnits }}>
            {children}
        </SettingsContext.Provider>
    );
};