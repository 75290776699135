import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {Ad} from "../../Common/Ad/Ad";
import {Jumbotron} from "../../Common/Jumbotrom/Jumbotron";

export const Home: FunctionComponent = () => {
    return (
        <div>
            <Jumbotron>
                <h1 className="display-4">
                    Camparator
                </h1>
                <p className="col-12 offset-0 col-md-8 offset-md-2 lead">
                    Easily compare different types of climbing gear! Including cams, nuts, hexes, and more!
                </p>
            </Jumbotron>
            <div className={"container"}>
                <div className="row">
                    <div id="active" className="col-lg-6 text-center">
                        <h2>Active</h2>
                        <p className="lead">
                            Compare different types of Active protection.
                        </p>
                        <div>
                            <Link role="button" className="btn btn-primary" to={"/compare/active/"}>
                                Compare Active »
                            </Link>
                        </div>
                        <div className="mt-3">
                            <Link role="button" className="btn btn-xs btn-light me-1" to={"/compare/cam/"}>
                                Cams »
                            </Link>
                            <Link role="button" className="btn btn-xs btn-light" to={"/compare/ballnut/"}>
                                Ball Nuts »
                            </Link>
                        </div>
                    </div>
                    <div id="passive" className="col-lg-6 text-center">
                        <h2>Passive</h2>
                        <p className="lead">
                            Compare different types of Passive protection.
                        </p>
                        <div>
                            <Link role="button" className="btn btn-primary" to={"/compare/passive/"}>
                                Compare Passive »
                            </Link>
                        </div>
                        <div className="mt-3">
                            <Link role="button" className="btn btn-xs btn-light me-1" to={"/compare/nut/"}>
                                Nuts »
                            </Link>
                            <Link role="button" className="btn btn-xs btn-light" to={"/compare/hex/"}>
                                Hexes »
                            </Link>
                        </div>
                        <div className="mt-1">
                            <Link role="button" className="btn btn-xs btn-light me-1" to={"/compare/tricam/"}>
                                Tricams »
                            </Link>
                            <Link role="button" className="btn btn-xs btn-light" to={"/compare/bigbro/"}>
                                Big Bros »
                            </Link>
                        </div>
                    </div>
                </div>
                <Ad className={"mt-2"} slot={"6224195689"}/>
            </div>
        </div>
    )
}
