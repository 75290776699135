import React, {ChangeEvent, FunctionComponent, useMemo, useState} from "react";
import styles from "./TipJar.module.css"
import {Utils} from "../../../utils/Utils";
import ConfigService from "../../../service/ConfigService";
import {TipJarRecurring} from "../../../model/TipJar";

export const TipJar: FunctionComponent = () => {
    const info = ConfigService.getTipJarInfo()
    const [currencyId, setCurrencyId] = useState("usd")
    const currency = useMemo(() => {
        return info.currencies[currencyId]
    }, [info, currencyId])
    const [amount, setAmount] = useState(1)
    const [recurring, setRecurring] = useState<TipJarRecurring>(TipJarRecurring.ONE_OFF)

    const link = useMemo(() => {
        if(!currency) return ""
        const options = currency.options.filter(option => option.amount === amount)
        if(options.length === 0) {
            return ""
        }
        const option = options[0]
        switch (recurring) {
            case TipJarRecurring.ONE_OFF:
                return option.links.oneOff
            case TipJarRecurring.MONTHLY:
                return option.links.monthly
        }
    }, [amount, currency, recurring])

    const handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const currencyId = event.target.value
        setCurrencyId(currencyId)
        const amounts = info.currencies[currencyId].options
            .map(option => option.amount)
        if(!amounts.includes(amount)) {
            setAmount(amounts[0])
        }
    }

    const handleAmountChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setAmount(parseInt(event.target.value))
    }

    const handleRecurringChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value as TipJarRecurring
        setRecurring(Utils.validatedEnum(TipJarRecurring, value, TipJarRecurring.ONE_OFF))
    }

    return (
        <div className={styles.tipJar}>
            <h1>Tip Jar</h1>
            <p>
                Despite Camparator being covered in ads the website does not break even (who knew comparing climbing
                gear was a niche market?). So if you wish to tip something then please do so bellow.
            </p>
            <p>
                Don't worry if you don't &#8211; I won't be offended &#128578;
            </p>
            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                <div className="btn-group" role="group">
                    <select className={styles.firstDropdown} value={currencyId} onChange={handleCurrencyChange}>
                        {Object.keys(info.currencies).map(id => (
                            <option key={id} value={id}>
                                {info.currencies[id].name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="btn-group" role="group">
                    <select className={styles.middleDropdown} value={amount} onChange={handleAmountChange}>
                        {(currency?.options ?? []).map(option => (
                            <option key={option.amount} value={option.amount}>
                                {currency.symbol}{option.amount}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="btn-group" role="group">
                    <select className={styles.middleDropdown} value={recurring} onChange={handleRecurringChange}>
                        <option value={TipJarRecurring.ONE_OFF}>
                            One-Off
                        </option>
                        <option value={TipJarRecurring.MONTHLY}>
                            Monthly
                        </option>
                    </select>
                </div>
                <a role="button" className={"btn btn-primary" + (link === "" ? " disabled" : "")} href={link}>
                    Tip
                </a>
            </div>
        </div>
    )
}