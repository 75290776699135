import {UtilsJs} from "../../../utils/UtilsJs";
import {Route} from "react-router-dom";
import {Footer} from "./Footer/Footer";
import {SettingsModal} from "./SettingsModal/SettingsModal";
import React, {useMemo} from "react";

export default function PageRoute(props) {
    const fullWidth = !!props.fullWidth;
    const noFooter = !!props.noFooter;

    const routeProps = UtilsJs.objectWithoutProperties(props, ["component", "children"]);

    const metaDescription = useMemo(() => {
        return document.querySelector("meta[name=description]")
    }, [])

    let Component = props.component;

    const setTitle = (title) => {
        document.title = (title ? title + " | " : "") + "Camparator";
    };

    const setDescription = (description) => {
        metaDescription.content = description ?? ""
    };

    const Page = ({title, description, children}) => {
        setTitle(title);
        setDescription(description);
        return children;
    };

    const Main = ({fullWidth, children}) => {
        if(fullWidth) {
            return (
                <main>
                    {children}
                </main>
            );
        } else {
            return (
                <main className="container" role="main">
                    {children}
                </main>
            )
        }
    }

    return (
        <Route {...routeProps}>
            <Page title={props.title} description={props.description}>
                <Main fullWidth={fullWidth}>
                    {props.component ? (
                        <Component {...props} match={props.computedMatch} setTitle={setTitle}
                                   setDescription={setDescription}/>
                    ) : props.children}
                </Main>
                {noFooter ? null : <Footer/>}
            </Page>
            <SettingsModal/>
        </Route>
    )
}
