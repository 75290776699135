import {useQuery} from "@apollo/client";
import {GET_HEXES_QUERY} from "../queries";
import {ItemHex} from "../../model/item/ItemHex";
import {ApolloError} from "@apollo/client/errors";

export function useGetHexes(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemHex[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_HEXES_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        return {loading: undefined, error: error, items: data.items.hexes.result}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    hexes: Hexes;
}

export interface Hexes {
    result: ItemHex[];
}