import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {info} from "../../../info"
import ConfigService from "../../../service/ConfigService";
import Loading from "../../Common/Loading/Loading";

type ActuatorInfo = {
    git: {
        branch: string
        commit: {
            id: string
            time: string
        }
    }
    build: {
        time: string
    }
}

export const Info: FunctionComponent = () => {
    const [actuatorInfo, setActuatorInfo] = useState<ActuatorInfo|null>(null)

    const fetchActuatorInfo = useCallback(async () => {
        let response = await fetch(ConfigService.getApiAddress() + "/actuator/info")
        let json = await response.json()
        return json as ActuatorInfo
    }, [])

    useEffect(() => {
        fetchActuatorInfo()
            .then(async response => {
                setActuatorInfo(response)
            })
    }, [fetchActuatorInfo])

    return (
        <div>
            <h1>Info</h1>
            <table className="table table-bordered">
                <tbody>
                    {/* UI: Git */}
                    <tr>
                        <th rowSpan={9}>UI</th>
                        <th rowSpan={2}>Commit</th>
                        <th>ID</th>
                        <td>{info.git.id}</td>
                    </tr>
                    <tr>
                        <th>Time</th>
                        <td>{info.git.time}</td>
                    </tr>

                    {/* UI: Build */}
                    <tr>
                        <th>Build</th>
                        <th>Time</th>
                        <td>{info.build.time}</td>
                    </tr>

                    {/* UI: App */}
                    <tr>
                        <th rowSpan={3}>App</th>
                        <th>Environment</th>
                        <td>{ConfigService.getEnvironmentString()}</td>
                    </tr>
                    <tr>
                        <th>Node Environment</th>
                        <td>{process.env.NODE_ENV}</td>
                    </tr>
                    <tr>
                        <th>API Address</th>
                        <td>{ConfigService.getApiAddress()}</td>
                    </tr>

                    {/* UI: Ads */}
                    <tr>
                        <th rowSpan={3}>Ads</th>
                        <th>Client</th>
                        <td>{ConfigService.getAdClient()}</td>
                    </tr>
                    <tr>
                        <th>Placeholder?</th>
                        <td>{ConfigService.isPlaceholderAd() ? "True" : "False"}</td>
                    </tr>
                    <tr>
                        <th>Test?</th>
                        <td>{ConfigService.isAdTest() ? "True" : "False"}</td>
                    </tr>

                    {/* API: Git */}
                    <tr>
                        <th rowSpan={3}>API</th>
                        <th rowSpan={2}>Commit</th>
                        <th>ID</th>
                        {actuatorInfo != null ? (
                            <td>{actuatorInfo.git.commit.id}</td>
                        ):(
                            <td rowSpan={3}>
                                <Loading/>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <th>Time</th>
                        {actuatorInfo != null ? (
                            <td>{actuatorInfo.git.commit.time}</td>
                        ):null}
                    </tr>

                    {/* API: Build */}
                    <tr>
                        <th>Build</th>
                        <th>Time</th>
                        {actuatorInfo != null ? (
                            <td>{actuatorInfo.build.time}</td>
                        ):null}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
