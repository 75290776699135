import React, {FunctionComponent} from "react";
import {Redirect, useParams} from "react-router-dom";
import {NotFound} from "../NotFound/NotFound";

type PermaProps = {
    setTitle: (title: string|undefined) => void
    setDescription: (description: string|undefined) => void
}

type PermaParams = {
    slug: string
}

export const Perma: FunctionComponent<PermaProps> = ({setTitle, setDescription}) => {
    const { slug } = useParams<PermaParams>();

    switch (slug) {
        case "dragonfly-vs-alien":
            return <Redirect to={"/compare/cam/AsOtAsOuAsOvAsOwAsOxAsOyAMKLAMKMAMKNAMKOAMKPAMKQ"}/>
        case "dragonfly-vs-camalot-x4":
            return <Redirect to={"/compare/cam/ABcAGAAZABoAGwAcAsOtAsOuAsOvAsOwAsOxAsOy"}/>
    }

    return (
        <NotFound setTitle={setTitle} setDescription={setDescription}/>
    )
}