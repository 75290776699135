import React, {FunctionComponent} from "react";
import {useParams} from "react-router-dom";
import {UidUtils} from "../../../utils/UidUtils";
import {TableServiceFactory} from "../../../service/TableService/TableServiceFactory";
import {NotFound} from "../NotFound/NotFound";
import {CompareSelectionInternal} from "./CompareSelectionInternal";

type CompareSelectionProps = {
    setTitle: (title: string|undefined) => void
    setDescription: (description: string|undefined) => void
}

type CompareSelectionParams = {
    itemTypeId: string
    encodedUids: string
}

export const CompareSelection: FunctionComponent<CompareSelectionProps> = ({setTitle, setDescription}) => {
    const { itemTypeId, encodedUids } = useParams<CompareSelectionParams>();

    const tableService = TableServiceFactory.create(itemTypeId)

    if(tableService == null) {
        return (
            <NotFound setTitle={setTitle} setDescription={setDescription}/>
        )
    }

    setTitle(tableService.getTitle())

    const uids = UidUtils.base64ToUids(encodedUids)

    return (
        <CompareSelectionInternal itemTypeId={itemTypeId} tableService={tableService} uids={uids}
                                  setDescription={setDescription}/>
    )
}