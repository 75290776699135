import {useGetEverything} from "../../networking/hooks/useGetEverything";
import {BaseTableService} from "./BaseTableService";
import {Item} from "../../model/item/Item";
import {Units} from "../../model/settings/Units";

export class EverythingTableService extends BaseTableService<Item> {
    constructor() {
        super(useGetEverything);
    }

    getName(): string {
        return "Everything"
    }

    getNameForDescription(): string {
        return "gear"
    }

    getNameForDescriptionPlural(): string {
        return "gear"
    }

    getTitle(): string {
        return "Compare Climbing Gear"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            this.weight(units),
            this.strength(units),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units),
        ];
    }
}
