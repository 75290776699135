import React, {FunctionComponent, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Analytics} from "../../../analytics/Analytics";
import {SettingsContext, SettingsContextType} from "../../../contexts/SettingsContext";

export const AnalyticsTrack: FunctionComponent = () => {
    const location = useLocation()
    const { units } = React.useContext(SettingsContext) as SettingsContextType;
    const [ isLoaded, setIsLoaded ] = useState(false)

    useEffect(() => {
        Analytics.instance.unitsSize = units.size
        Analytics.instance.unitsStrength = units.strength
        Analytics.instance.unitsWeight = units.weight
        if(isLoaded) {
            Analytics.instance.report().then()
        } else {
            Analytics.instance.setUp()
                .then(() => {
                    setIsLoaded(true)
                })
        }
    }, [isLoaded, units])

    useEffect(() => {
        Analytics.instance.pageViewEvent(location.pathname, location.search)
            .then()
    }, [location])

    return null
}