import {ItemCam} from "../../model/item/ItemCam";
import {useGetCams} from "../../networking/hooks/useGetCams";
import {BaseTableService} from "./BaseTableService";
import SelectColumnFilter from "../../components/Common/ItemsTable/Filter/SelectColumnFilter";
import {Units} from "../../model/settings/Units";

export class CamTableService extends BaseTableService<ItemCam> {
    constructor() {
        super(useGetCams);
    }

    getName(): string {
        return "Cam"
    }

    getColumns(units: Units): any[] {
        return [
            this.name(),
            this.generation(),
            this.brand(),
            this.model(),
            this.size(),
            this.color(),
            {
                Header: 'Axle',
                accessor: (item: ItemCam) => item.model?.axle?.name,
                Filter: SelectColumnFilter,
                filter: 'equals',
                canFilter: true,
                priority: 4,
            },
            {
                Header: 'Stem Flexibility',
                accessor: (item: ItemCam) => item.model?.stemFlexibility?.name,
                canFilter: false,
                priority: 9,
            },
            {
                Header: 'Stem',
                accessor: (item: ItemCam) => item.model?.stem?.name,
                canFilter: false,
                priority: 9,
            },
            {
                Header: 'Lobes',
                accessor: (item: ItemCam) => item.model?.lobes,
                Filter: SelectColumnFilter,
                filter: 'equals',
                canFilter: true,
                priority: 3,
            },
            {
                Header: 'Sling Loop',
                accessor: (item: ItemCam) => item.model?.slingLoop?.name,
                canFilter: false,
                priority: 9,
            },
            this.weight(units),
            this.strength(units),
            this.strengthCustom({
                headerNoUnits: "Passive Strength",
                accessor: (item: ItemCam) => item.passiveStrength,
                priority: 9,
                units: units
            }),
            this.rangeLower(units),
            this.rangeUpper(units),
            this.range(units)
        ];
    }
}
