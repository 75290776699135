import {FunctionComponent, useEffect} from "react";

type AdsenseCssHackProps = {
    ids: string[]
}

/**
 * I hate that this is required.
 * https://news.ycombinator.com/item?id=20870609
 * @param ids A list of element ids that should be saved from adsense's grasps
 */
export const AdsenseCssHack: FunctionComponent<AdsenseCssHackProps> = ({ids}) => {

    useEffect(() => {
        const elements = ids
            .map(id => document.getElementById(id))
            .filter(e => e) as [HTMLElement]

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const target = mutation.target as HTMLElement
                target.style.height = "";
            })
        })

        elements.forEach(element => {
            observer.observe(element, {
                attributes: true,
                attributeFilter: ['style']
            })
        })
    }, [ids])

    return null
}