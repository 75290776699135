import React from "react";
import {ItemUtils} from "../../../../utils/ItemUtils";

export default function ColorCell({row: {original}, value, className}) {
    return (
        <td role="cell" style={ItemUtils.determineColorStyle(original)} className={className}>
            {value}
        </td>
    )
}
