import React from "react";

export default function SortableIndicator({column}) {
    if (!column.isSorted) {
        return null
    }
    return (
        <>
            &nbsp;
            {column.isSortedDesc ? (
                <i className="fa fa-caret-down" aria-hidden="true"/>
            ) : (
                <i className="fa fa-caret-up" aria-hidden="true"/>
            )}
        </>
    )
}