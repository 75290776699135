import React from "react";

export default function ColumnFilter({column}) {
    if (!column.canFilter) return null

    return (
        <div>
            {column.render('Filter')}
        </div>
    )
}