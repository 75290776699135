import {Item} from "../model/item/Item";
import {UnitType} from "../model/settings/UnitType";
import {UnitsUtils} from "./UnitsUtils";

export class StatisticsUtils {

    static itemsWeightString(items: Item[], unitType: UnitType): string {
        const totalGrams = this.itemsTotalGrams(items)
        switch (unitType) {
            case UnitType.METRIC:
                const {kilos, grams} = UnitsUtils.gramsToKilosAndGrams(totalGrams)
                return this.formatBigAndSmallUnits(kilos, "kg", grams, "g")
            case UnitType.IMPERIAL:
                const totalOunces = UnitsUtils.gramsToOunces(totalGrams)
                const {pounds, ounces} = UnitsUtils.ouncesToPoundsAndOunces(totalOunces)
                return this.formatBigAndSmallUnits(pounds, "lb", ounces.toFixed(UnitsUtils.ouncesFractionDigits), "oz")
        }
    }

    private static itemsTotalGrams(items: Item[]): number {
        if(items.length === 0) return 0
        return Math.round(
            items
                .map(item => item.weight ?? 0)
                .reduce((a, b) => {
                    if(b < 0) {
                        return a
                    }
                    return a + b
                })
        )
    }

    private static formatBigAndSmallUnits(value1: number|string, unit1: string, value2: number|string, unit2: string): string {
        let result = ""
        if(value1 > 0) {
            result += value1 + unit1 + " "
        }
        result += value2 + unit2
        return result
    }
}