import React, {ChangeEvent, FunctionComponent} from "react";
import styles from "./SettingsModal.module.css";
import {UnitType} from "../../../../model/settings/UnitType";
import {SettingsContext, SettingsContextType} from "../../../../contexts/SettingsContext";

type SelectUnitsProps = {
    value: UnitType,
    setValue: (value: UnitType) => void
    units: {metric: string, imperial: string}
}

const SelectUnits: FunctionComponent<SelectUnitsProps> = ({value, setValue, units}) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(event.target.value as UnitType)
    }

    return (
        <select className={styles.select} value={value} onChange={handleChange}>
            <option value="metric">Metric ({units.metric})</option>
            <option value="imperial">Imperial ({units.imperial})</option>
        </select>
    )
}

export const SettingsModal: FunctionComponent = () => {
    const { units, setUnits } = React.useContext(SettingsContext) as SettingsContextType;

    const setUnitsSize = (size: UnitType) => setUnits({...units, size: size})
    const setUnitsWeight = (weight: UnitType) => setUnits({...units, weight: weight})
    const setUnitsStrength = (strength: UnitType) => setUnits({...units, strength: strength})

    // @ts-ignore
    return (
        <div className="modal fade" id="settingsModal" tabIndex={-1} aria-labelledby="itemInfoModalTitle"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="settingsModalTitle">
                            Settings
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <strong>Units</strong>
                        <table className={"table table-bordered"}>
                            <tbody>
                                <tr>
                                    <th>
                                        Size
                                    </th>
                                    <td>
                                        <SelectUnits value={units.size} setValue={setUnitsSize}
                                                     units={{metric: "mm", imperial: "in"}}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Weight
                                    </th>
                                    <td>
                                        <SelectUnits value={units.weight} setValue={setUnitsWeight}
                                                     units={{metric: "g", imperial: "oz"}} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Strength
                                    </th>
                                    <td>
                                        <SelectUnits value={units.strength} setValue={setUnitsStrength}
                                                     units={{metric: "kN", imperial: "lbf"}} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}