import {useQuery} from "@apollo/client";
import {GET_ACTIVE_QUERY} from "../queries";
import {BallNuts} from "./useGetBallNuts";
import {Cams} from "./useGetCams";
import {ItemActive} from "../../model/item/ItemActive";
import {ApolloError} from "@apollo/client/errors";

export function useGetActive(): {loading: boolean|undefined, error: ApolloError|undefined, items: ItemActive[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_ACTIVE_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        let cams: ItemActive[] = data.items.cams.result
        let ballNuts: ItemActive[] = data.items.ballNuts.result
        let items = cams.concat(ballNuts)
        return {loading: undefined, error: error, items: items}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    cams: Cams;
    ballNuts: BallNuts;
}