import {AnalyticsInstance} from "./AnalyticsInstance";

export abstract class BaseAnalytics {
    private readonly instance: String
    private readonly appId: String
    private appInstance: AnalyticsInstance|null

    constructor(instance: String, appId: String) {
        this.instance = instance
        this.appId = appId
        this.appInstance = BaseAnalytics.getAppInstance()
    }

    abstract constructReport(): any

    async setUp() {
        await this.getAppInstanceOrRegister()
    }

    async register(): Promise<AnalyticsInstance> {
        const url = this.instance + "/v1/app/" + this.appId + "/instance"
        const response = await window.fetch(url, {
            method: "POST"
        })
        const {uuid, token} = await response.json()
        localStorage.setItem("analytics-uuid", uuid)
        localStorage.setItem("analytics-token", token)
        const appInstance = {uuid, token}
        this.appInstance = appInstance
        return appInstance
    }

    async report() {
        const appInstance = this.getAppInstanceOrFail()
        const url = this.instance + "/v1/app/" + this.appId + "/instance/report"
        const report = this.constructReport()
        await window.fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "App-Instance-Id": appInstance.uuid.toString(),
                "App-Instance-Token": appInstance.token.toString(),
            },
            body: JSON.stringify(report)
        })
    }

    async event(type: String, event: any) {
        const appInstance = this.getAppInstanceOrFail()
        const url = this.instance + "/v1/app/" + this.appId + "/instance/event/" + type

        await window.fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "App-Instance-Id": appInstance.uuid.toString(),
                "App-Instance-Token": appInstance.token.toString(),
            },
            body: JSON.stringify(event)
        })
    }

    private getAppInstanceOrFail(): AnalyticsInstance {
        if(this.appInstance == null) {
            throw Error("Analytics does not have an app instance")
        }
        return this.appInstance
    }

    private async getAppInstanceOrRegister(): Promise<AnalyticsInstance> {
        const existingAppInstance = BaseAnalytics.getAppInstance()
        if(existingAppInstance != null) {
            return existingAppInstance
        }
        return await this.register()
    }

    private static getAppInstance(): AnalyticsInstance | null {
        const uuid = localStorage.getItem("analytics-uuid")
        const token = localStorage.getItem("analytics-token")
        if(uuid == null || token == null) {
            return null
        } else {
            return {uuid, token}
        }
    }
}