import React, {FunctionComponent} from "react";
import {Jumbotron} from "../../Common/Jumbotrom/Jumbotron";
import styles from "./MobileApp.module.css"
import {Assets} from "../../../Assets";

export const MobileApp: FunctionComponent = () => {
    return (
        <div>
            <Jumbotron className={styles.jumbotron}>
                <h1 className="display-4">
                    Camparator for iOS
                </h1>
                <p className="col-12 offset-0 col-md-8 offset-md-2 lead">
                    Climbing gear comparison for iPhone, iPad and iPod Touch!
                </p>
            </Jumbotron>
            <div className={styles.iosAppIcon}>
                <img className={styles.iosAppIconImg} src={Assets.APPLE_ICON} alt="Camparator iOS App Icon"/>
                <div className={styles.iosAppIconBorder}/>
            </div>
            <div className="container">
                <div className={"d-sm-flex justify-content-center"}>
                    <div className={styles.text}>
                        <p>
                            Camparator for iOS brings the climbing gear comparison of the web to your mobile device. Easily
                            compare different types of climbing gear! Including cams, nuts, hexes, and more!
                        </p>
                        <p>
                            Each item can be easily compared on a chart to visualise its size compared to the other items.
                            This is especially useful for comparing gear models from different manufacturers or evaluating
                            any holes in a mismatched rack.
                        </p>
                        <a href="https://apps.apple.com/us/app/camparator/id1476776736" target="_blank" rel="noreferrer">
                            <img style={{height: "60px"}} src={Assets.DOWNLOAD_ON_APP_STORE} alt="Download on the App Store"/>
                        </a>
                    </div>
                    <div className={styles.iPhoneScreenshot}>
                        <img src={Assets.IOS_APP_SCREENSHOT} alt="Camparator iOS App Screenshot"/>
                    </div>
                </div>
            </div>
        </div>
    )
}