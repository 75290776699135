export class Utils {
    static arrayRange(start: number, end: number, steps: number = 1): number[] {
        if(steps === 0) {
            return []
        }
        if(steps < 0) {
            steps = -steps
        }
        let arr: number[] = []
        if(start <= end) {
            for(let i = start; i <= end; i += steps) {
                arr.push(i)
            }
        } else {
            for(let i = start; i >= end; i -= steps) {
                arr.push(i)
            }
        }
        return arr
    }

    static priorityToClass(priority: number) {
        switch (priority) {
            case -1: // Columns with priority -1 should always be displayed
                return "d-table-cell d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell";

            case 0: // Columns with priority 0 should only be displayed on XS screen
                return "d-table-cell d-sm-none d-md-none d-lg-none d-xl-none";

            case 1: // Columns with priority 1 should only be displayed on SM screen and larger (But not on XS screen)
                return "d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell";

            case 2: // Columns with priority 2 should only be displayed on MD screen and larger (But not on XS screen)
                return "d-none d-sm-none d-md-table-cell d-lg-table-cell d-xl-table-cell";

            case 3: // Columns with priority 2 should only be displayed on LG screen and larger (But not on XS screen)
                return "d-none d-sm-none d-md-none d-lg-table-cell d-xl-table-cell";

            case 4: // Columns with priority 4 should only be displayed on MD screen and larger (But not on XS screen)
                return "d-none d-sm-none d-md-none d-lg-none d-xl-table-cell";

            default: // All other columns should not be displayed
                return "d-none d-sm-none d-md-none d-lg-none d-xl-none";
        }
    }

    /**
     * Takes a raw enum value and ensure that it actually contains a value of that Enum.
     * E.g. JSON parsed from user may be set to value that isn't valid. In this case fall back to defaultValue,
     * @param enumType      The enum type
     * @param rawValue      The raw value. MUST be enum. Behaviour for non enum values is undefined.
     * @param defaultValue  Value to use if rawValue is null, undefined or invalid
     */
    static validatedEnum<T>(enumType: { [s: string]: T }, rawValue: T|null|undefined, defaultValue: T): T {
        if(!rawValue) {
            return defaultValue
        }
        if(Object.values(enumType).includes(rawValue)) {
            return rawValue
        }
        return defaultValue
    }

    static classNames(names: (string|undefined|null)[]): string {
        const namesFiltered = names.filter(a => a) as string[]
        return namesFiltered.join(" ")
    }

    static listToHumanReadable(list: string[]):string {
        if(list.length === 0) {
            return ""
        } else if(list.length === 1) {
            return list[0]
        }

        const commaSeparated = list.splice(0, list.length - 1)

        return `${commaSeparated.join(", ")} and ${list[0]}`
    }

    static arrayPushAndReturn<T>(array: T[], item: T):T[] {
        array.push(item)
        return array
    }
}