import React from "react";
import styles from "./MobileFilterModal.module.css"

export function MobileFilterModal({headerGroups}) {
    return (
        <div className="modal fade" id="mobileFilterModal" tabIndex={-1} aria-labelledby="mobileFilterModalTitle"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="mobileFilterModalTitle">
                            Filter
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-bordered"}>
                            <tbody>
                                {headerGroups.map((headerGroup, i) => (
                                    headerGroup.headers.map((column) => {
                                        const filter = column.Filter({column})
                                        if(!filter) {
                                            return null
                                        }
                                        return (
                                            <tr key={`"filter_row_${i}_${column.id}"`}>
                                                <th>{column.Header}</th>
                                                <td className={styles.filter}>
                                                    {filter}
                                                </td>
                                            </tr>
                                        )
                                    })
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}