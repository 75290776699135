import {FunctionComponent} from "react";
import {EmailLink} from "../../Common/EmailLink/EmailLink";

export const PrivacyIOS: FunctionComponent = () => {
    return (
        <div>
            <h1>Privacy Policy (iOS)</h1>
            <p>
                This policy applies to the Camparator app for iOS (including iPhone and iPad).
            </p>
            <h2>Information we collect</h2>
            <p>
                Your IP address may be included in log files on the server used to sync the latest gear to your device.
                This data is never stored with any other personally identifiable information.
            </p>
            <p>
                The app collects anonymous statistics about app usage. This includes no personally identifiable information.
            </p>

            <h2>Information Usage</h2>
            <p>
                Your IP address is only used to debug issues with the service and will not be stored long term. Anonymous
                app usage information is used to operate and improve the app.
            </p>
            <p>
                We may disclose your information in response to subpoenas, court orders, or other legal requirements;
                to exercise our legal rights or defend against legal claims; to investigate, prevent, or take action
                regarding illegal activities, suspected fraud or abuse, violations of our policies; or to protect our
                rights and property.
            </p>
            <p>
                In the future, we may sell to, buy, merge with, or partner with other businesses. In such transactions,
                user information may be among the transferred assets.
            </p>

            <h2>Your Consent</h2>
            <p>
                By using our app, you consent to this privacy policy.
            </p>

            <h2>Contacting Us</h2>
            <p>
                If you have questions regarding this privacy policy, you may email: <EmailLink address={"hello@camparator.com"}/>
            </p>

            <h2>Changes to this policy</h2>
            <p>
                If we decide to change our privacy policy, we will post those changes on this page. Summary of changes
                so far:
                <ul>
                    <li>
                        <strong>August 16, 2019</strong>: First published.
                    </li>
                    <li>
                        <strong>November 11, 2020</strong>: Vastly simplified by removing Firebase analytics and other
                        tracking.
                    </li>
                    <li>
                        <strong>January 14, 2023</strong>: Addition of anonymous analytics.
                    </li>
                </ul>
            </p>
        </div>
    )
}