import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useFilters, useGlobalFilter, useSortBy, useTable} from "react-table";
import {InfoModal} from "./InfoModal/InfoModal";
import styles from "./ItemsTable.module.css"
import {Body} from "./Body/Body";
import {Header} from "./Header/Header";
import {Actions} from "./Actions/Actions";
import {MobileFilterModal} from "./MobileFilterModal/MobileFilterModal";

export function ItemsTable({items, columns, compareSelected, canSelect = true, initiallySelected = []}) {
    const [isFiltering, setIsFiltering] = useState(false)
    const [infoItem, setInfoItem] = useState(null)
    const selectedIdsRef = useRef(initiallySelected)
    const selectAllRef = useRef()
    const bodyRef = useRef()

    const defaultColumn = useMemo(() => ({
        Filter: () => null,
    }), [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: items,
            defaultColumn: defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    )
    const getTableBodyPropsRef = useRef(getTableBodyProps)

    /**
     * Refresh the state of the select all checkbox (i.e. set it to checked/unchecked/indeterminate, based on
     * selected items
     */
    const refreshSelectAll = useCallback(() => {
        if(!selectAllRef.current) return

        let indeterminate
        let checked

        if(items.length === 0) {
            indeterminate = false
            checked = false
        } else {
            indeterminate = selectedIdsRef.current.length > 0 && selectedIdsRef.current.length < items.length
            checked = selectedIdsRef.current.length === items.length
        }

        selectAllRef.current.indeterminate = indeterminate
        selectAllRef.current.checked = checked
    }, [items.length])

    /**
     * Refresh the select all checkbox state whenever the table state changes
     */
    useEffect(() => {
        refreshSelectAll()
    }, [refreshSelectAll, state])

    /**
     * Called when a selected row has been altered
     * @param id    The item's UID
     * @param state Boolean. True if selected, False if deselected
     */
    const didChangeSelected = useCallback((id, state) => {
        if(state && !selectedIdsRef.current.includes(id)) {
            selectedIdsRef.current.push(id)
        } else if(!state && selectedIdsRef.current.includes(id)) {
            selectedIdsRef.current = selectedIdsRef.current.filter(e => e !== id)
        }

        refreshSelectAll()
    }, [refreshSelectAll])

    return (
        <div>
            <Actions isFiltering={isFiltering} setIsFiltering={setIsFiltering} globalFilter={state.globalFilter}
                     setGlobalFilter={setGlobalFilter} filters={state.filters} canSelect={canSelect}
                     compareSelected={() => compareSelected(selectedIdsRef.current)}/>
            <table className={"table " + styles.table} {...getTableProps()}>
                <Header headerGroups={headerGroups} rows={rows} bodyRef={bodyRef} selectedIdsRef={selectedIdsRef}
                             selectAllRef={selectAllRef} canSelect={canSelect} isFiltering={isFiltering} />
                <Body getTableBodyProps={getTableBodyPropsRef.current} bodyRef={bodyRef} rows={rows} prepareRow={prepareRow}
                      didChangeSelected={didChangeSelected} selectedIdsRef={selectedIdsRef} setInfoItem={setInfoItem}
                      canSelect={canSelect} />
            </table>
            <InfoModal columns={columns} item={infoItem} />
            <MobileFilterModal headerGroups={headerGroups} />
        </div>
    )
}