import {useQuery} from "@apollo/client";
import {GET_ITEMS_QUERY} from "../queries";
import {ApolloError} from "@apollo/client/errors";
import {Item} from "../../model/item/Item";
import {Cams} from "./useGetCams";
import {BallNuts} from "./useGetBallNuts";
import {ItemPassive} from "../../model/item/ItemPassive";
import {ItemActive} from "../../model/item/ItemActive";
import {BigBros} from "./useGetBigBros";
import {Hexes} from "./useGetHexes";
import {Nuts} from "./useGetNuts";
import {Tricams} from "./useGetTricams";

export function useGetEverything(): {loading: boolean|undefined, error: ApolloError|undefined, items: Item[]|undefined} {
    const { loading, error, data } = useQuery<QueryData>(GET_ITEMS_QUERY);
    if (loading) {
        return {loading: loading, error: undefined, items: undefined}
    }
    if (error) {
        return {loading: undefined, error: error, items: undefined}
    }
    if (data) {
        let cams: ItemActive[] = data.items.cams.result
        let ballNuts: ItemActive[] = data.items.ballNuts.result
        let bigBros: ItemPassive[] = data.items.bigBros.result
        let hexes: ItemPassive[] = data.items.hexes.result
        let nuts: ItemPassive[] = data.items.nuts.result
        let tricams: ItemPassive[] = data.items.tricams.result
        let items = cams.concat(ballNuts, bigBros, hexes, nuts, tricams)
        return {loading: undefined, error: error, items: items}
    }
    return {loading: undefined, error: undefined, items: undefined}
}

interface QueryData {
    items: Items;
}

interface Items {
    cams: Cams;
    ballNuts: BallNuts;
    bigBros: BigBros;
    hexes: Hexes;
    nuts: Nuts;
    tricams: Tricams;
}