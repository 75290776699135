import React, {FunctionComponent} from "react";
import {Placeholder} from "../../Common/Ad/AdInternal/Placeholder/Placeholder";
import {Blocked} from "../../Common/Ad/AdInternal/Blocked/Blocked";
import {Real} from "../../Common/Ad/AdInternal/Real/Real";
import ConfigService from "../../../service/ConfigService";

export const Test: FunctionComponent = () => {
    const slot = "6224195689"

    return (
        <div>
            <Real client={ConfigService.getAdClient()} slot={slot} className={"mt-3"}/>
            <Placeholder slot={slot} className={"mt-3"}/>
            <Blocked className={"mt-3"}/>
        </div>
    )
}