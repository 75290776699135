import {InfoDetails} from "./components/Pages/Info/InfoDetails";

export const info: InfoDetails = {
    git: {
        id: "",
        time: ""
    },
    build: {
        time: "2024-02-05T21:54:24Z"
    }
}
