import React from "react";
import {Row} from "./Row/Row";

export const Body = React.memo((
    {
        getTableBodyProps,
        bodyRef,
        rows,
        prepareRow,
        didChangeSelected,
        selectedIdsRef,
        setInfoItem,
        canSelect
    }) => {

    return (
        <tbody {...getTableBodyProps()} ref={bodyRef}>
            {rows.map((row, i) => {
                prepareRow(row)
                return <Row key={row.original.id} row={row} didChangeSelected={didChangeSelected}
                            initialIsSelected={selectedIdsRef.current.includes(row.original.uid)}
                            showInfoModel={() => setInfoItem(row.original)} canSelect={canSelect}/>
            })}
        </tbody>
    )
})
