import React, {FunctionComponent} from "react";
import styles from "./Bar.module.css"
import {Item} from "../../../../model/item/Item";
import {Range} from "../../../../model/range-chart/Range";
import {FitText} from "./FitText/FitText";

type BarProps = {
    item: Item
    range: Range
}

export const Bar: FunctionComponent<BarProps> = ({item, range}) => {
    const rangeLower = item.rangeLower ?? 0
    const rangeUpper = item.rangeUpper ?? 0
    const barLeftPercent = parseFloat(
        Math.abs(100 - (range.max - rangeLower) * range.unitPercentSize)
            .toFixed(2)
    );
    const barWidthPercent = parseFloat(
        ((rangeUpper - rangeLower) * range.unitPercentSize)
            .toFixed(2)
    );
    const barRightPercent = parseFloat(
        (100 - barWidthPercent - barLeftPercent)
            .toFixed(2)
    );

    const isTextInBar = barWidthPercent > barRightPercent
    const name = `${item.model.brand.name} ${item.model.name} ${item.size}`

    return (
        <div className={styles.barContainer}>
            <div className={styles.bar} style={{

                color: `#${item.color.textHex}`,
                backgroundColor: `#${item.color.hex}`,
                width: `${barWidthPercent}%`,
                marginLeft: `${barLeftPercent}%`
            }}>
                {isTextInBar ? (
                    <div className={styles.barText}>
                        <FitText>
                            <span>{name}</span>
                        </FitText>
                    </div>
                ):null}
            </div>
            {!isTextInBar ? (
                <div className={styles.barTextPost}>
                    <FitText>
                        <span>{name}</span>
                    </FitText>
                </div>
            ):null}
            <div className={styles.barPost}/>
        </div>
    )
}