import React, {FunctionComponent} from "react";

type ErrorProps = {
    message: string
}

export const Error: FunctionComponent<ErrorProps> = ({message}) => {
    const tryAgain = () => {
        window.location.reload();
    }

    return (
        <div className="alert alert-danger" role="alert">
            <strong>Error</strong> {message}
            <button className="btn btn-link btn-anchor pull-right" onClick={tryAgain}>
                Try Again
            </button>
        </div>
    )
}