import {BaseTableService} from "./BaseTableService";
import {CamTableService} from "./CamTableService";
import {Item} from "../../model/item/Item";
import {BallNutTableService} from "./BallNutTableService";
import {ActiveTableService} from "./ActiveTableService";
import {PassiveTableService} from "./PassiveTableService";
import {BigBroTableService} from "./BigBroTableService";
import {HexTableService} from "./HexTableService";
import {NutTableService} from "./NutTableService";
import {TricamTableService} from "./TricamTableService";
import {EverythingTableService} from "./EverythingTableService";

export class TableServiceFactory {
    static create(itemTypeId: string): BaseTableService<Item>|null {
        switch (itemTypeId) {
            case "active":
                return new ActiveTableService()
            case "cam":
                return new CamTableService()
            case "ballnut":
                return new BallNutTableService()
            case "passive":
                return new PassiveTableService()
            case "bigbro":
                return new BigBroTableService()
            case "hex":
                return new HexTableService()
            case "nut":
                return new NutTableService()
            case "tricam":
                return new TricamTableService()
            case "all":
                return new EverythingTableService()
        }
        return null
    }
}